import React, { useContext } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { setSelectedHospital } from "../../actions/hospitals";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";

import ErrorContext from "../../context/ErrorContext";

//autocomplete dropdown with all hospitals from api call with select a hospital on click to see details
function HospitalList() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { setError } = useContext(ErrorContext);

  const hospitals = useSelector((state) => state.hospitals);
  const hospitalList = hospitals.hospitalList;

  async function handleSelectedHospital(id) {
    await dispatch(setSelectedHospital(id, hospitalList))
      .then(() => history.push(`/hospitals/${id}`))
      .catch((error) => {
        console.log(error);
        setError(error);
      });
  }

  return (
    <div className="container mt-3">
      <div className="header">
        <Link to="/hospitals/new" className="btn header">
          Add Hospital
        </Link>
        <hr />
        <h1>Current Hospitals</h1>
        <h5 style={{ color: "#015239" }}>
          Type or choose from drop down the hospital name to view details
        </h5>
      </div>
      <hr />
      <div
        style={{
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Autocomplete
          options={[...hospitalList].sort((a, b) =>
            a.Hospital.localeCompare(b.Hospital)
          )}
          getOptionLabel={(hospital) => hospital.Hospital}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          clearText="Clear"
          style={{ width: 300 }}
          onChange={(event, value) => handleSelectedHospital(value.id)}
          name="chosenHospital"
          renderInput={(params) => (
            <TextField {...params} label="Hospitals" variant="outlined" />
          )}
        />
      </div>
    </div>
  );
}

export default HospitalList;
