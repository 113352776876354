import React, { createContext, useState } from "react";

const LoadingContext = createContext({
  error: null,
  setError: () => null,
});

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(null);

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingContext;
