//user instructions to screen and authorize tissues and processors
export default function ScreenInstructions() {
  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr ",
        }}
      >
        <div>
          <h4>Screening</h4>
          <p
            style={{
              border: "1px #d7d2cb solid",
              padding: "1%",
            }}
          >
            Starting with the <strong>Musculoskeletal Processors</strong>, begin
            screening with the first processor in list.
          </p>
          <ul>
            <li>If they defer, move to the next Musculoskeletal Processor.</li>
            <li>
              If all Musculoskeletal Processors defer, <strong>only</strong>{" "}
              proceed to <strong>Cardiovascular Processor</strong> if it is an{" "}
              <strong>Extended/Regional </strong>hospital.
            </li>
            <li>
              If a Musculoskeletal Processor <strong>accepts</strong>, continue
              to <strong>Cardiovascular Processor </strong>and{" "}
              <strong>Nerve Processor</strong>
            </li>
          </ul>
        </div>
        <div style={{ marginLeft: "17%" }}>
          <h4>Authorization</h4>
          <p
            style={{
              fontWeight: "bold",
              border: "1px #d7d2cb solid",
              padding: "3%",
            }}
          >
            Authorize all tissue types in the list.
          </p>
          <ul>
            <li>
              If family <strong>declines </strong> Musculoskeletal Processors,
            </li>
            <ol>
              <li>
                <strong>Do not authorize Nerve Processors</strong>
              </li>
              <li>
                Only authorize <strong>Cardiovascular Processor </strong> if it
                is an <strong>Extended/Regional </strong>hospital
              </li>
            </ol>
          </ul>
        </div>
      </div>
      <hr />
    </div>
  );
}
