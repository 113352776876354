import { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

//list of all processor instances
export default function AgencyList({ onProcessorSelection }) {
  const processorList = useSelector((state) => state.processors.processorList);
  const [instances, setInstances] = useState();
  const [state, setState] = useState({
    top: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  //   remove duplicates, list all agencies
  const noDupes = processorList.filter(
    (v, i, s) =>
      i ===
      s.findIndex(
        (t) => t.agencyName === v.agencyName && t.agencyName === v.agencyName
      )
  );
  //show all instances of particular agency
  function showInstances(p) {
    const pInstances = processorList.filter(
      (proc) => proc.agencyName === p.agencyName
    );
    setInstances(pInstances);
  }
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
    >
      {noDupes
        .sort((a, b) => a.agencyName.localeCompare(b.agencyName))
        .map((p) => (
          <h4
            key={p.id}
            onClick={() => showInstances(p)}
            style={{ margin: "8%" }}
          >
            {p.agencyName}
            <hr />
          </h4>
        ))}
    </Box>
  );
  return (
    <div>
      <div>
        {["left"].map((anchor) => (
          <div key={anchor} style={{ marginTop: "2.5%" }}>
            <p className="view_by_agency">View by agency</p>
            <button
              className="btn btn-outline-light"
              onClick={toggleDrawer(anchor, true)}
            >
              Agencies
            </button>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {list(anchor)}
            </Drawer>
          </div>
        ))}
      </div>
      {instances ? (
        <div>
          <h4 className="agency_header">
            {instances[0]?.agencyName}{" "}
            <span className="hide" onClick={() => setInstances()}>
              Hide
            </span>
          </h4>
          <p>Click on an agency item for more information</p>
          {instances?.map((i) => (
            <Link key={i.id} to={`/processors/${i.id}`}>
              <div className="agency_processor_link_div">
                <b>Priority: </b> {i.priority} <b>Tissue Type: </b>{" "}
                {i.tissueType.tissueName} <b>Age Min: </b> {i.ageMin}{" "}
                <b>Age Max: </b> {i.ageMax} <b>Gender: </b> {i.gender}{" "}
                <b>Extended: </b> {i.extended ? "✔" : ""} <b>Regional: </b>{" "}
                {i.regional ? "✔" : ""} <b>Flight: </b> {i.flight ? "✔" : ""}
              </div>
            </Link>
          ))}
        </div>
      ) : null}
    </div>
  );
}
