import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser, updateAdmin, createUser } from '../actions/user';
import { useState, useEffect, useContext } from 'react';
import { Dialog } from '@mui/material';
import * as Msal from 'msal';

import ProcessorRouter from './Processor/ProcessorRouter';
import HospitalRouter from './Hospital/HospitalRouter';
import TissueRouter from './Tissue/TissueRouter';
import ScreenerForm from './Screener/ScreenerForm';
import ErrorContext from '../context/ErrorContext';
import ErrorModal from './ErrorModal/ErrorModal';
import LoadingContext from '../context/LoadingContext';
import LoadingModal from './LoadingModal/LoadingModal';
import ProtectedRoute from './ProtectedRoute/ProtectedRoute';
import LoggedInNavBar from './NavBar/LoggedInNavBar';
import LoggedOutNavBar from './NavBar/LoggedOutNavBar';

export default function App() {
  const dispatch = useDispatch();

  const { error, setError } = useContext(ErrorContext);
  const { loading, setLoading } = useContext(LoadingContext);

  const [state, setState] = useState({
    displayName: '',
    userId: '',
    egg: false,
  });

  const users = useSelector((state) => state.users);

  //msal azure ad log in
  const myMSALObj = new Msal.UserAgentApplication({
    auth: {
      clientId: process.env.REACT_APP_CLIENT_ID,
      authority: process.env.REACT_APP_AUTHORITY,
      validateAuthority: true,
      postLogoutRedirectUri: 'https://phast.lcnw.app/',
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true,
    },
  });
  //need callback for msal
  function authCallback(error) {
    if (error) {
      const errorMessage = error.errorMessage
        ? error.errorMessage
        : 'Unable to acquire access token';
      console.log(errorMessage);
    }
    acquireTokenPopupAndCallMSGraph();
  }
  myMSALObj.handleRedirectCallback(authCallback);
  //check url and reroute if azurewebsite
  useEffect(() => {
    if (window.location.href === 'https://phast.azurewebsites.net/') {
      window.location.href = 'https://phast.lcnw.app/';
    }
  }, []);
  //sign in button function
  function signIn() {
    const userRead = ['user.read'];
    myMSALObj.loginRedirect(userRead);
  }
  //Acquires the token using msal and runs function to call MS Graph
  function acquireTokenPopupAndCallMSGraph() {
    //Set the scope
    const userRead = ['user.read'];
    //Acquires the token from MSAL
    myMSALObj
      .acquireTokenSilent({ scopes: userRead })
      .then((tokenResponse) => {
        //Save access token to state
        callMSGraph(tokenResponse.accessToken);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //Gets the users profile data from Microsoft Graph API
  function callMSGraph(token) {
    //Use axios to get the users profile data
    axios
      .get('https://graph.microsoft.com/v1.0/me', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((profileData) => {
        dispatch(updateUser(profileData.data));
        setState({
          displayName: profileData.data.displayName,
          userId: profileData.data.id,
        });
        getMemberOf(token, profileData.data.displayName, profileData.data.id);
      });
  }
  //get azure ad group
  async function getMemberOf(token, displayName, userId) {
    var url = 'https://graph.microsoft.com/v1.0/me/memberOf';

    await axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        let isAdmin = false;
        for (let i = 0; i < response.data.value.length; i++) {
          if (
            response.data.value[i].displayName ===
            process.env.REACT_APP_ADMINGROUP
          ) {
            isAdmin = true;
          }
        }
        if (isAdmin === true) {
          dispatch(updateAdmin(isAdmin));
          setState({
            displayName: displayName,
            userId: userId,
          });
        } else {
          setState({ egg: true });
        }
        const newUser = {
          displayName,
          userId,
          isAdmin,
        };
        dispatch(createUser(newUser));
      })
      .catch((error) => {
        setError(error);
      });
  }
  //handle unauthorized user
  function handleEgg() {
    setState({ egg: false });
  }
  //developer sign in
  async function devSignIn() {
    dispatch(updateAdmin(true));
    setState({
      displayName: 'DEV',
      userId: process.env.REACT_APP_DEV_ID,
    });
    const newUser = {
      displayName: 'DEV',
      userId: process.env.REACT_APP_DEV_ID,
      isAdmin: true,
    };
    setLoading(true);
    await dispatch(createUser(newUser))
      .then(() => setLoading(false))
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setError(error);
      });
  }

  return (
    <Router>
      {error ? <ErrorModal /> : null}
      {loading ? <LoadingModal /> : null}
      {state.egg ? (
        <div>
          <Dialog
            open={state.egg === true}
            onClose={handleEgg}>
            <div
              style={{
                padding: '10.5%',
                display: 'block',
                margin: 'auto',
                textAlign: 'center',
              }}>
              <h4>
                Log in failed. <hr />
              </h4>
              <h4>
                {' '}
                Please contact <br />
                <a href='mailto:ithelpdesk@lcnw.org'> LCNW IT HelpDesk</a>{' '}
              </h4>
              <button
                className='btn'
                style={{ backgroundColor: '#c8db2a' }}
                onClick={() => handleEgg()}>
                Back
              </button>
            </div>
          </Dialog>
        </div>
      ) : null}
      {users.newUser.isAdmin ? (
        <LoggedInNavBar users={users} />
      ) : (
        <LoggedOutNavBar
          users={users}
          devSignIn={devSignIn}
          signIn={signIn}
        />
      )}
      <Switch>
        <Route
          path='/screener'
          component={ScreenerForm}
        />
        <ProtectedRoute path='/processors'>
          <ProcessorRouter />
        </ProtectedRoute>
        <ProtectedRoute path='/hospitals'>
          <HospitalRouter />
        </ProtectedRoute>
        <ProtectedRoute path='/tissues'>
          <TissueRouter />
        </ProtectedRoute>
        <Route path='/*'>
          <ScreenerForm />
        </Route>
      </Switch>
    </Router>
  );
}
