import {
  CREATE_TISSUE,
  GET_TISSUES,
  UPDATE_TISSUE,
  DELETE_TISSUE,
  DELETE_ALL_TISSUES,
  SET_SELECTED_TISSUE,
} from "../actions/ActionTypes";

const initalState = { tissueList: [], selectedTissue: null };

function tissueReducer(state = { ...initalState }, action) {
  const { type, payload } = action;
  const newState = { ...state };
  switch (type) {
    case CREATE_TISSUE:
      newState.tissueList = [...newState.tissueList, payload];
      return newState;
    case GET_TISSUES:
      newState.tissueList = payload;
      return newState;
    case UPDATE_TISSUE:
      newState.tissueList = payload.tissueList;
      newState.selectedTissue = payload.tissue;
      return newState;

    case DELETE_TISSUE:
      newState.tissueList = payload.tissueList;
      return newState;

    case DELETE_ALL_TISSUES:
      newState.tissueList = [];
      newState.selectedTissue = null;
      return newState;

    case SET_SELECTED_TISSUE:
      newState.selectedTissue = payload;
      return newState;
    default:
      return newState;
  }
}

export default tissueReducer;
