//api calls for users
import UserDataService from "../services/user.service";

const initialState = {
  userData: {},
  isAdmin: false,
  newUser: {},
};

const UPDATE_USER = "UPDATE_USER";
const UPDATE_ADMIN = "UPDATE_ADMIN";
const CREATE_USER = "CREATE_USER";

export function updateUser(newUserData) {
  // console.log(newUserData);
  return {
    type: UPDATE_USER,
    payload: newUserData,
  };
}

export function updateAdmin(newStatus) {
  // console.log(newStatus);
  return {
    type: UPDATE_USER,
    payload: newStatus,
  };
}

export const createUser =
  ({ displayName, userId, isAdmin }) =>
  async (dispatch) => {
    try {
      const res = await UserDataService.create({
        displayName,
        userId,
        isAdmin,
      });
      dispatch({
        type: CREATE_USER,
        payload: res.data,
      });

      return Promise.resolve(res.data);
    } catch (err) {
      return { err };
    }
  };

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_USER:
      return Object.assign({}, state, { userData: action.payload });
    case UPDATE_ADMIN:
      return Object.assign({}, state, { isAdmin: action.payload });
    case CREATE_USER:
      return Object.assign({}, state, { newUser: action.payload });
    default:
      return state;
  }
}
