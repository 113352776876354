import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";

import ReusableHospitalForm from "./ReusableHospitalForm";
import { updateHospital } from "../../actions/hospitals";
import ErrorContext from "../../context/ErrorContext";
//update selected hospital
function EditHospitalForm({ id, selectedHospital, user, hospitalList }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { setError } = useContext(ErrorContext);

  //update hospital form submission
  async function handleEditHospitalFormSubmission(event) {
    event.preventDefault();
    const updatedHospital = {
      Hospital: event.target.Hospital.value,
      MS_Skin_Ext: event.target.MS_Skin_Ext.checked ? "Yes" : "",
      MS_Skin_Reg: event.target.MS_Skin_Reg.checked ? "Yes" : "",
      MS_Skin_Flight: event.target.MS_Skin_Flight.checked ? "Yes" : "",
      MS_Ext: event.target.MS_Ext.checked ? "Yes" : "",
      MS_Reg: event.target.MS_Reg.checked ? "Yes" : "",
      MS_Flight: event.target.MS_Flight.checked ? "Yes" : "",
      Skin_Ext: event.target.Skin_Ext.checked ? "Yes" : "",
      Skin_Reg: event.target.Skin_Reg.checked ? "Yes" : "",
      Skin_Flight: event.target.Skin_Flight.checked ? "Yes" : "",
      Hv_Ext: event.target.Hv_Ext.checked ? "Yes" : "",
      Hv_Reg: event.target.Hv_Reg.checked ? "Yes" : "",
      Hv_Flight: event.target.Hv_Flight.checked ? "Yes" : "",
      HVN_Ext: event.target.HVN_Ext.checked ? "Yes" : "",
      HVN_Reg: event.target.HVN_Reg.checked ? "Yes" : "",
      HVN_Flight: event.target.HVN_Flight.checked ? "Yes" : "",
      id: selectedHospital.id,
      userId: user.userId,
    };

    await dispatch(
      updateHospital(selectedHospital.id, updatedHospital, hospitalList)
    )
      .then(() => history.push(`/hospitals/${selectedHospital.id}`))
      .catch((error) => {
        console.log(error);
        setError(error);
      });
  }

  return (
    <div className="container mt-3">
      <div className="header">
        <Link to="/hospitals" className="btn header">
          Return to List
        </Link>
        <hr />
        <h1>Edit : {selectedHospital.Hospital}</h1>
        <hr />
      </div>
      <ReusableHospitalForm
        selectedHospital={selectedHospital}
        formSubmissionHandler={handleEditHospitalFormSubmission}
        buttonText="Update"
        cancel={true}
      />
    </div>
  );
}

export default EditHospitalForm;
