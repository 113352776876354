import { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';

import { deleteTissue } from '../../actions/tissues';
import ErrorContext from '../../context/ErrorContext';

function TissueDetail({ selectedTissue, tissueList, id, user }) {
  const dispatch = useDispatch();
  const history = useHistory();
  // error context
  const { setError } = useContext(ErrorContext);
  // delete dialog
  const [isOpen, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (event) => {
    event.preventDefault();
    const data = selectedTissue.id + `,${user.userId}`;
    await dispatch(deleteTissue(data, tissueList))
      .then(() => history.push('/tissues'))
      .catch((error) => {
        console.log(error);
        setError(error);
      });
  };

  return (
    <div className='container mt-3'>
      <Dialog
        fullWidth
        maxWidth='sm'
        open={isOpen}
        onClose={handleClose}>
        <DialogTitle>Delete Tissue</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Confirm that you would like to delete this Tissue
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            className='btn btn-danger'
            onClick={handleDelete}
            autoFocus>
            Confirm
          </button>
          <button
            className='btn btn-outline-dark'
            onClick={handleClose}>
            Close
          </button>
        </DialogActions>
      </Dialog>
      <div className='header'>
        <Link
          to='/tissues'
          className='btn header'>
          Return to List
        </Link>
        <hr />
        <h1 className='header_text'>{selectedTissue.tissueName}</h1>
        <hr />
      </div>
      <h5 className='tissue_scores'>
        <span className='tissue_score'>Extended: </span>{' '}
        {selectedTissue.ext_score}{' '}
        <span className='tissue_score'>Regional: </span>{' '}
        {selectedTissue.reg_score}{' '}
        <span className='tissue_score'>Flight: </span>{' '}
        {selectedTissue.flight_score}
      </h5>
      <hr />
      <Link to={`/tissues/${id}/edit`}>
        <button className='btn update'>Update Tissue</button>
      </Link>
      <button
        className='btn btn-danger'
        onClick={handleOpen}>
        Delete Tissue
      </button>
    </div>
  );
}

export default TissueDetail;
