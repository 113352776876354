//api calls with axios connection
import http from "../http-common";

class TissueDataService {
  getAll() {
    return http.get("/tissue");
  }

  get(id) {
    return http.get(`/tissue/${id}`);
  }

  create(data) {
    return http.post("/tissue", data);
  }

  update(id, data) {
    return http.put(`/tissue/${id}`, data);
  }

  delete(data) {
    return http.delete(`/tissue/${data}`);
  }

  deleteAll() {
    return http.delete("/tissue");
  }

  findByName(name) {
    return http.get(`/tissueName/${name}`);
  }
}
const tissueDataService = new TissueDataService();

export default tissueDataService;
