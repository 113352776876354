//api calls with axios connection
import http from "../http-common";

class HospitalDataService {
  getAll() {
    return http.get("/hospital");
  }

  get(id) {
    return http.get(`/hospital/${id}`);
  }

  create(data) {
    return http.post("/hospital", data);
  }

  update(id, data) {
    return http.put(`/hospital/${id}`, data);
  }

  delete(id) {
    return http.delete(`/hospital/${id}`);
  }

  deleteAll() {
    return http.delete("/hospital");
  }

  findByHospitalName(hospital) {
    return http.get(`/hospitalName/${hospital}`);
  }
}
const hospitalDataService = new HospitalDataService();
export default hospitalDataService;
