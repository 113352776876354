// api calls with axios connection
import http from "../http-common";

class ProcessorDataService {
  getAll() {
    return http.get("/processor");
  }

  get(id) {
    return http.get(`/processor/${id}`);
  }

  create(data) {
    return http.post("/processor", data);
  }

  update(id, data) {
    return http.put(`/processor/${id}`, data);
  }

  updateOrder(data) {
    return http.put("/processor/order", data);
  }

  delete(id) {
    return http.delete(`/processor/${id}`);
  }

  deleteAll() {
    return http.delete("/processor");
  }

  findByGender(gender) {
    return http.get(`/processorByGender/${gender}`);
  }

  findByName(name) {
    return http.get(`/processorName/${name}`);
  }

  getAllProcessorTypes() {
    return http.get("/processor/types");
  }
}

const processorDataService = new ProcessorDataService();

export default processorDataService;
