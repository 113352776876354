import React, { useContext } from "react";

import LoadingContext from "../../context/LoadingContext";
import "./LoadingModal.css";
const LoadingModal = () => {
  const { loading } = useContext(LoadingContext);

  if (!loading) {
    return null;
  }

  return (
    <div className="loading-modal">
      <div className="loading-modal-content">
        <h2>Loading . . .</h2>
        <hr />
        <span className="loader" />
        <hr />
      </div>
    </div>
  );
};

export default LoadingModal;
