export const CREATE_PROCESSOR = "CREATE_PROCESSOR";
export const GET_PROCESSORS = "GET_PROCESSORS";
export const UPDATE_PROCESSOR = "UPDATE_PROCESSOR";
export const DELETE_PROCESSOR = "DELETE_PROCESSOR";
export const DELETE_ALL_PROCESSORS = "DELETE_ALL_PROCESSORS";
export const UPDATE_PROCESSOR_ORDER = "UPDATE_PROCESSOR_ORDER";
export const GET_PROCESSOR_TYPES = "GET_PROCESSOR_TYPES";
export const SET_SELECTED_PROCESSOR = "SET_SELECTED_PROCESSOR";

export const CREATE_TISSUE = "CREATE_TISSUE";
export const GET_TISSUES = "GET_TISSUES";
export const UPDATE_TISSUE = "UPDATE_TISSUE";
export const DELETE_TISSUE = "DELETE_TISSUE";
export const DELETE_ALL_TISSUES = "DELETE_ALL_TISSUES";
export const SET_SELECTED_TISSUE = "SET_SELECTED_TISSUE";

export const CREATE_HOSPITAL = "CREATE_HOSPITAL";
export const GET_HOSPITALS = "GET_HOSPITALS";
export const UPDATE_HOSPITAL = "UPDATE_HOSPITAL";
export const DELETE_HOSPITAL = "DELETE_HOSPITAL";
export const DELETE_ALL_HOSPITALS = "DELETE_ALL_HOSPITALS";
export const SET_SELECTED_HOSPITAL = "SET_SELECTED_HOSPITAL";
