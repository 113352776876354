import { Route, Switch } from "react-router-dom";
import { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import ErrorContext from "../../context/ErrorContext";
import NewTissueForm from "./NewTissueForm";
import EditTissueForm from "./EditTissueForm";
import TissueDetail from "./TissueDetail";
import TissueList from "./TissueList";
import { TissueLoader } from "./TissueLoader";
import { getTissues } from "../../actions/tissues";
import "./TissueStyles.css";

const TissueRouter = () => {
  const dispatch = useDispatch();

  const { setError } = useContext(ErrorContext);
  const tissueList = useSelector((state) => state.tissues.tissueList);

  useEffect(() => {
    if (tissueList && !tissueList.length) {
      dispatch(getTissues()).catch((error) => {
        console.log(error);
        setError(error);
      });
    }
  }, [dispatch, setError, tissueList]);

  return (
    <Switch>
      <Route path="/tissues/new" component={NewTissueForm} />
      <Route path="/tissues/:id/edit">
        <TissueLoader Component={EditTissueForm} />
      </Route>
      <Route path="/tissues/:id">
        <TissueLoader Component={TissueDetail} />
      </Route>
      <Route path="/tissues" component={TissueList} />
    </Switch>
  );
};

export default TissueRouter;
