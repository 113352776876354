import React, { createContext, useState } from "react";

const ErrorContext = createContext({
  error: null,
  setError: () => null,
});

export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState(null);

  return (
    <ErrorContext.Provider value={{ error, setError }}>
      {children}
    </ErrorContext.Provider>
  );
};

export default ErrorContext;
