import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import ErrorContext from "../../context/ErrorContext";
import { setSelectedHospital } from "../../actions/hospitals";

export const HospitalLoader = ({ Component }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { setError } = useContext(ErrorContext);
  const [loaded, setLoaded] = useState(false);

  const user = useSelector((state) => state.users.newUser);
  const hospitalList = useSelector((state) => state.hospitals.hospitalList);
  const selectedHospital = useSelector(
    (state) => state.hospitals.selectedHospital
  );

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        if (selectedHospital && selectedHospital.id === id) {
          setLoaded(true);
        } else {
          await dispatch(setSelectedHospital(Number(id), hospitalList));
          if (isMounted) {
            setLoaded(true);
          }
        }
      } catch (error) {
        console.error(error);
        if (isMounted) {
          setError(error);
        }
      }
    };

    fetchData();

    // Cleanup function to cancel subsciprtion to dispatch and avoid memory leak
    return () => {
      // Set to false when the component is unmounted
      isMounted = false;
    };
  }, [dispatch, selectedHospital, hospitalList, id, setError]);

  if (!loaded) {
    return <h1>Loading...</h1>;
  } else {
    return (
      <Component
        selectedHospital={selectedHospital}
        hospitalList={hospitalList}
        id={id}
        user={user}
      />
    );
  }
};
