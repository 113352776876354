//api calls with axios connection
import http from "../http-common";

class UserDataService {
  create(data) {
    return http.post("/user", data);
  }
}

const userDataService = new UserDataService();

export default userDataService;
