import {
  CREATE_HOSPITAL,
  GET_HOSPITALS,
  UPDATE_HOSPITAL,
  DELETE_HOSPITAL,
  DELETE_ALL_HOSPITALS,
  SET_SELECTED_HOSPITAL,
} from "../actions/ActionTypes";

const initalState = { hospitalList: [], selectedHospital: null };

function hospitalReducer(state = { ...initalState }, action) {
  const { type, payload } = action;
  const newState = { ...state };
  switch (type) {
    case CREATE_HOSPITAL:
      newState.hospitalList = [...newState.hospitalList, payload];
      newState.selectedHospital = payload;
      return newState;
    case GET_HOSPITALS:
      newState.hospitalList = payload;
      return newState;
    case UPDATE_HOSPITAL:
      newState.selectedHospital = payload.hospital;
      newState.hospitalList = payload.hospitalList;
      return newState;
    case DELETE_HOSPITAL:
      newState.hospitalList = payload.hospitalList;
      return newState;
    case DELETE_ALL_HOSPITALS:
      newState.hospitalList = [];
      return newState;
    case SET_SELECTED_HOSPITAL:
      newState.selectedHospital = payload;
      return newState;
    default:
      return newState;
  }
}

export default hospitalReducer;
