import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
  Paper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ProcessorDataService from "../../services/processor.service";
import ScreenInstructions from "./ScreenerInstructions";
//table styles based on processor
const useStyles = makeStyles({
  table: {
    width: "90%",
  },
  ms: {
    backgroundColor: "#FFFFFF",
  },
  cv: {
    backgroundColor: "#d9e569",
  },
  nv: {
    backgroundColor: "#6e5b46",
    color: "white",
  },
  colorPrimary: {
    backgroundColor: "#c8db2a",
  },
});

function ScreenerResult(props) {
  const classes = useStyles();

  //set fetched processors to processorGenderData
  const [processorGenderData, setProcessorGenderData] = useState("");

  //api call to get all processors from db that match input gender
  useEffect(() => {
    ProcessorDataService.findByGender(props.onNewDataResults.gender)
      .then((response) => {
        setProcessorGenderData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.onNewDataResults.gender]);
  let hospitalTransData = [];
  let finalProcessors = [];
  let groups = [];
  let waitFlag = false;
  //screener algorithm
  //start function if hook sets api data to processorGenderData
  if (processorGenderData !== "") {
    //filter processors by input age
    let inputAge;
    //if input in months, math to double else input to double to match db data type
    if (props.onNewDataResults.chosenAge === "months") {
      inputAge = (parseInt(props.onNewDataResults.ageNumber) / 12).toFixed(2);
    } else {
      inputAge = parseInt(props.onNewDataResults.ageNumber).toFixed(2);
    }
    const ageFilteredProcessors = processorGenderData.filter(
      (p) => inputAge >= p.ageMin && inputAge <= p.ageMax
    );
    //filter hospital by "Yes" values and return hospital object with key values of tissue_transportation: "Yes"
    const hospitalData = props.onNewDataResults.chosenHospital;
    Object.filter = (obj, yes) => Object.entries(obj).filter(yes);
    const filteredHospitalData = Object.filter(
      hospitalData,
      ([key, value]) => value === "Yes"
    );

    const hospitalTrans = filteredHospitalData.map((x) => x[0]);
    //format hospital transportation type
    for (let i = 0; i < hospitalTrans.length; i++) {
      if (hospitalTrans[i].substr(hospitalTrans[i].length - 3) === "Ext") {
        hospitalTransData.push("Extended");
      }
      if (hospitalTrans[i].substr(hospitalTrans[i].length - 3) === "Reg") {
        hospitalTransData.push("Regional");
      }
      if (hospitalTrans[i].substr(hospitalTrans[i].length - 3) === "ght") {
        hospitalTransData.push("Flight");
      }
    }
    hospitalTransData = [...new Set(hospitalTransData)];
    //filter processors based on hospital transportation
    //disable linting for filter
    // eslint-disable-next-line
    finalProcessors = ageFilteredProcessors.filter((p) => {
      for (let i = 0; i < hospitalTrans.length; i++) {
        if (
          p.flight_score === hospitalTrans[i] ||
          p.reg_score === hospitalTrans[i] ||
          p.ext_score === hospitalTrans[i]
        ) {
          return true;
        }
      }
    });

    //remove double agency names
    const groupByAgencyName = finalProcessors.reduce((group, product) => {
      const { agencyName, processorType } = product;
      group[agencyName] = group[agencyName] ?? [];
      group[agencyName].push(product);
      group[agencyName].processorType = processorType;
      return group;
    }, {});
    let checkG = [];
    const gAgency = Object.entries(groupByAgencyName);

    //remove duplicate tissue types
    for (let i = 0; i < gAgency.length; i++) {
      const agencyTissuetypes = gAgency[i][1];
      const rudeTissues = agencyTissuetypes.reduce((group, d) => {
        const { tissueType } = d;
        group[tissueType.tissueName] = group[tissueType.tissueName] ?? [];
        group[tissueType.tissueName].push({ tissueType });
        return group;
      }, {});
      checkG.push({
        agencyName: gAgency[i][0],
        tissueTypes: Object.keys(rudeTissues),
        processorType: gAgency[i][1].processorType,
      });
    }

    groups = checkG;
    waitFlag = true;
  }

  function processorHeader(processorType) {
    if (processorType === "ms") {
      return "Musculoskeletal Processor";
    } else if (processorType === "cv") {
      return "Cardiovascular Processor";
    } else {
      return "Nerve Processor";
    }
  }

  return (
    <>
      <div className="container mt-3">
        <div>
          <h1 style={{ textAlign: "center" }}>Tissue Screener Results</h1>

          <hr />
          <ScreenInstructions />
          <h3 style={{ textAlign: "center", wordSpacing: "2px" }}>
            Age:{" "}
            <strong>
              {props.onNewDataResults.ageNumber}{" "}
              {props.onNewDataResults.chosenAge === "months"
                ? "Months "
                : "Years "}
            </strong>
            Gender: <strong>{props.onNewDataResults.gender} </strong>
            Hospital:{" "}
            <strong>{props.onNewDataResults.chosenHospital.Hospital}</strong>
          </h3>
          <div style={{ textAlign: "right" }}>
            <p>
              Hospital Information:{" "}
              <strong>
                {hospitalTransData.map((data, i) => (i ? ", " : "") + data)}
              </strong>
            </p>
          </div>
        </div>
        <hr />
        <TableContainer component={Paper} align="center">
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Processor</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>Tissue Type</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {finalProcessors.length === 0 ? (
                waitFlag === true ? (
                  <TableRow>
                    <TableCell style={{ color: "red" }}>
                      No processors available
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell>
                      {" "}
                      <LinearProgress className={classes.colorPrimary} />
                    </TableCell>
                  </TableRow>
                )
              ) : (
                groups.map((processor, index) => (
                  <TableRow
                    key={index}
                    className={classes[processor.processorType.type]}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes[processor.processorType.type]}
                    >
                      {processor.agencyName === "Artivion" ? (
                        <div>
                          <p>
                            <strong>
                              {processorHeader(processor.processorType.type)}
                            </strong>
                          </p>
                          {processor.agencyName} <br />
                          <span style={{ fontSize: "12px" }}>
                            *Must be accompanied with Bones & Associated Tissues
                            of the Arms/Legs Tissue on flight cases
                          </span>
                        </div>
                      ) : processor.agencyName === "Axogen" ? (
                        <div>
                          <p>
                            <strong>
                              {processorHeader(processor.processorType.type)}
                            </strong>
                          </p>
                          {processor.agencyName} <br />
                          <span style={{ fontSize: "12px" }}>
                            *Can only be recovered with Bones & Associated
                            Tissues of the Arms/Legs Tissue
                          </span>
                        </div>
                      ) : (
                        <div>
                          {" "}
                          <p>
                            <strong>
                              {processorHeader(processor.processorType.type)}
                            </strong>
                          </p>
                          {processor.agencyName}
                        </div>
                      )}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ whiteSpace: "pre-wrap" }}
                      className={classes[processor.processorType.type]}
                    >
                      {processor.tissueTypes.join("\n")}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <hr />
        <button
          onClick={props.onBackButton}
          className="btn"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            backgroundColor: "#c8db2a",
            marginBottom: "30%",
          }}
        >
          Back to Screener Form
        </button>
      </div>
    </>
  );
}
export default ScreenerResult;
