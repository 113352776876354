import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import ReusableTissueForm from "./ReusableTissueForm";
import { createTissue } from "../../actions/tissues";
import ErrorContext from "../../context/ErrorContext";

//create new tissue
function NewTissueForm() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { setError } = useContext(ErrorContext);

  const user = useSelector((state) => state.users.newUser.userId);

  async function handleNewTissueFormSubmission(event) {
    event.preventDefault();

    const ext = event.target.MS_Skin_Ext.checked
      ? "MS_Skin_Ext"
      : event.target.MS_Ext.checked
      ? "MS_Ext"
      : event.target.Skin_Ext.checked
      ? "Skin_Ext"
      : event.target.Hv_Ext.checked
      ? "Hv_Ext"
      : event.target.HVN_Ext.checked
      ? "HVN_Ext"
      : "";
    const reg = event.target.MS_Skin_Reg.checked
      ? "MS_Skin_Reg"
      : event.target.MS_Reg.checked
      ? "MS_Reg"
      : event.target.Skin_Reg.checked
      ? "Skin_Reg"
      : event.target.Hv_Reg.checked
      ? "Hv_Reg"
      : event.target.HVN_Reg.checked
      ? "HVN_Reg"
      : "";
    const flight = event.target.MS_Skin_Flight.checked
      ? "MS_Skin_Flight"
      : event.target.MS_Flight.checked
      ? "MS_Flight"
      : event.target.Skin_Flight.checked
      ? "Skin_Flight"
      : event.target.Hv_Flight.checked
      ? "Hv_Flight"
      : event.target.HVN_Flight.checked
      ? "HVN_Flight"
      : "";
    const newTissue = {
      tissueName: event.target.tissueName.value,
      ext_score: ext,
      reg_score: reg,
      flight_score: flight,
      userId: user,
    };
    await dispatch(createTissue(newTissue))
      .then(() => history.push("/tissues"))
      .catch((error) => {
        console.log(error);
        setError(error);
      });
  }
  return (
    <div className="container mt-3">
      <div className="header">
        <Link to="/tissues" className="btn header">
          Return to List
        </Link>
        <hr />
        <h1 className="header_text">New Tissue</h1>
        <hr />
      </div>
      <ReusableTissueForm
        formSubmissionHandler={handleNewTissueFormSubmission}
        buttonText="Submit"
      />
    </div>
  );
}

export default NewTissueForm;
