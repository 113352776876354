import React, { useState } from "react";
import {
  FormControl,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Container,
  Grid,
} from "@mui/material";
import { useHistory } from "react-router-dom";

//form for both create and edit functions
function ReusableTissueForm(props) {
  const history = useHistory();

  const [tissue] = useState(props.selectedTissue ? props.selectedTissue : "");

  return (
    <div className="container mt-3">
      <Container>
        <form onSubmit={props.formSubmissionHandler}>
          <TextField
            required
            name="tissueName"
            defaultValue={tissue.tissueName ? tissue.tissueName : ""}
            id="standard-full-width"
            label="Tissue Name"
            margin="normal"
            fullWidth
          />
          <hr />
          <Grid
            container
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
              gap: "60px",
            }}
          >
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="MS_Skin_Ext"
                      name="MS_Skin_Ext"
                      defaultChecked={
                        tissue.ext_score === "MS_Skin_Ext" ? true : false
                      }
                    />
                  }
                  label="MS_Skin_Ext"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="MS_Skin_Reg"
                      name="MS_Skin_Reg"
                      defaultChecked={
                        tissue.reg_score === "MS_Skin_Reg" ? true : false
                      }
                    />
                  }
                  label="MS_Skin_Reg"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="MS_Skin_Flight"
                      name="MS_Skin_Flight"
                      defaultChecked={
                        tissue.flight_score === "MS_Skin_Flight" ? true : false
                      }
                    />
                  }
                  label="MS_Skin_Flight"
                />
              </FormGroup>
            </FormControl>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="MS_Ext"
                      name="MS_Ext"
                      defaultChecked={
                        tissue.ext_score === "MS_Ext" ? true : false
                      }
                    />
                  }
                  label="MS_Ext"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="MS_Reg"
                      name="MS_Reg"
                      defaultChecked={
                        tissue.reg_score === "MS_Reg" ? true : false
                      }
                    />
                  }
                  label="MS_Reg"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="MS_Flight"
                      name="MS_Flight"
                      defaultChecked={
                        tissue.flight_score === "MS_Flight" ? true : false
                      }
                    />
                  }
                  label="MS_Flight"
                />
              </FormGroup>
            </FormControl>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="Skin_Ext"
                      name="Skin_Ext"
                      defaultChecked={
                        tissue.ext_score === "Skin_Ext" ? true : false
                      }
                    />
                  }
                  label="Skin_Ext"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="Skin_Reg"
                      name="Skin_Reg"
                      defaultChecked={
                        tissue.reg_score === "Skin_Reg" ? true : false
                      }
                    />
                  }
                  label="Skin_Reg"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="Skin_Flight"
                      name="Skin_Flight"
                      defaultChecked={
                        tissue.flight_score === "Skin_Flight" ? true : false
                      }
                    />
                  }
                  label="Skin_Flight"
                />
              </FormGroup>
            </FormControl>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="Hv_Ext"
                      name="Hv_Ext"
                      defaultChecked={
                        tissue.ext_score === "Hv_Ext" ? true : false
                      }
                    />
                  }
                  label="Hv_Ext"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="Hv_Reg"
                      name="Hv_Reg"
                      defaultChecked={
                        tissue.reg_score === "Hv_Reg" ? true : false
                      }
                    />
                  }
                  label="Hv_Reg"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="Hv_Flight"
                      name="Hv_Flight"
                      defaultChecked={
                        tissue.flight_score === "Hv_Flight" ? true : false
                      }
                    />
                  }
                  label="Hv_Flight"
                />
              </FormGroup>
            </FormControl>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="HVN_Ext"
                      name="HVN_Ext"
                      defaultChecked={
                        tissue.ext_score === "HVN_Ext" ? true : false
                      }
                    />
                  }
                  label="HVN_Ext"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="HVN_Reg"
                      name="HVN_Reg"
                      defaultChecked={
                        tissue.reg_score === "HVN_Reg" ? true : false
                      }
                    />
                  }
                  label="HVN_Reg"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="HVN_Flight"
                      name="HVN_Flight"
                      defaultChecked={
                        tissue.flight_score === "HVN_Flight" ? true : false
                      }
                    />
                  }
                  label="HVN_Flight"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <hr />
          <button className="btn update" type="submit">
            {props.buttonText}
          </button>
          {props.buttonText === "Update" && (
            <button
              className="btn btn-danger"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancel Update
            </button>
          )}
        </form>
      </Container>
    </div>
  );
}

export default ReusableTissueForm;
