import { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";

import ErrorContext from "../../context/ErrorContext";
import { createHospital } from "../../actions/hospitals";
import ReusableHospitalForm from "./ReusableHospitalForm";

//create new hospital
function NewHospitalForm() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { setError } = useContext(ErrorContext);

  const user = useSelector((state) => state.users.newUser.userId);

  async function handleNewHospitalFormSubmission(event) {
    event.preventDefault();
    const newHospital = {
      Hospital: event.target.Hospital.value,
      MS_Skin_Ext: event.target.MS_Skin_Ext.checked ? "Yes" : "",
      MS_Skin_Reg: event.target.MS_Skin_Reg.checked ? "Yes" : "",
      MS_Skin_Flight: event.target.MS_Skin_Flight.checked ? "Yes" : "",
      MS_Ext: event.target.MS_Ext.checked ? "Yes" : "",
      MS_Reg: event.target.MS_Reg.checked ? "Yes" : "",
      MS_Flight: event.target.MS_Flight.checked ? "Yes" : "",
      Skin_Ext: event.target.Skin_Ext.checked ? "Yes" : "",
      Skin_Reg: event.target.Skin_Reg.checked ? "Yes" : "",
      Skin_Flight: event.target.Skin_Flight.checked ? "Yes" : "",
      Hv_Ext: event.target.Hv_Ext.checked ? "Yes" : "",
      Hv_Reg: event.target.Hv_Reg.checked ? "Yes" : "",
      Hv_Flight: event.target.Hv_Flight.checked ? "Yes" : "",
      HVN_Ext: event.target.HVN_Ext.checked ? "Yes" : "",
      HVN_Reg: event.target.HVN_Reg.checked ? "Yes" : "",
      HVN_Flight: event.target.HVN_Flight.checked ? "Yes" : "",
      userId: user,
    };

    await dispatch(createHospital(newHospital))
      .then(() => history.push("/hospitals"))
      .catch((error) => {
        console.log(error);
        setError(error);
      });
  }

  return (
    <div className="container mt-3">
      <div className="header">
        <Link to="/hospitals" className="btn header">
          Return to List
        </Link>
        <hr />
        <h1 style={{ textAlign: "center" }}>New Hospital</h1>
        <hr />
      </div>

      <ReusableHospitalForm
        formSubmissionHandler={handleNewHospitalFormSubmission}
        buttonText="Submit"
      />
    </div>
  );
}

export default NewHospitalForm;
