//api calls for tissues
import {
  CREATE_TISSUE,
  GET_TISSUES,
  UPDATE_TISSUE,
  DELETE_TISSUE,
  DELETE_ALL_TISSUES,
  SET_SELECTED_TISSUE,
} from "./ActionTypes";
import TissueDataService from "../services/tissue.service";

export const createTissue = (data) => async (dispatch) => {
  try {
    const res = await TissueDataService.create(data);
    dispatch({
      type: CREATE_TISSUE,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getTissues = () => async (dispatch) => {
  try {
    const res = await TissueDataService.getAll();
    dispatch({
      type: GET_TISSUES,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateTissue = (id, data, tissueList) => async (dispatch) => {
  try {
    const res = await TissueDataService.update(id, data);
    const updatedTissueList = tissueList.map((tissue) => {
      if (tissue.id === Number(id)) {
        return res.data;
      } else {
        return tissue;
      }
    });
    dispatch({
      type: UPDATE_TISSUE,
      payload: { tissue: res.data, tissueList: updatedTissueList },
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteTissue = (data, tissueList) => async (dispatch) => {
  try {
    const res = await TissueDataService.delete(data);

    const updatedTissueList = tissueList.filter(
      (tissue) => tissue.id !== res.data.id
    );
    dispatch({
      type: DELETE_TISSUE,
      payload: { tissueId: res.data.id, tissueList: updatedTissueList },
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteAllTissues = () => async (dispatch) => {
  try {
    const res = await TissueDataService.deleteAll();
    dispatch({
      type: DELETE_ALL_TISSUES,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findByTissueName = (name) => async (dispatch) => {
  try {
    const res = await TissueDataService.findByName(name);
    dispatch({
      type: GET_TISSUES,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const setSelectedTissue = (id, tissues) => async (dispatch) => {
  //if tissue is found in tissues ignore the redundant api call
  let tissue;
  if (tissues.length)
    tissue = tissues.find((tissue) => Number(tissue.id) === Number(id));
  if (tissue) {
    dispatch({ type: SET_SELECTED_TISSUE, payload: tissue });
  } else {
    try {
      const res = await TissueDataService.get(id);
      dispatch({
        type: SET_SELECTED_TISSUE,
        payload: res.data,
      });
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
};
