import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import ErrorContext from "../../context/ErrorContext";
import { setSelectedProcessor } from "../../actions/processors";
export const ProcessorLoader = ({ Component }) => {
  const dispatch = useDispatch();

  const { setError } = useContext(ErrorContext);
  const [loaded, setLoaded] = useState(false);
  const { id } = useParams();

  const user = useSelector((state) => state.users.newUser);
  const processorList = useSelector((state) => state.processors.processorList);
  const selectedProcessor = useSelector(
    (state) => state.processors.selectedProcessor
  );

  useEffect(() => {
    if (selectedProcessor && selectedProcessor.id === Number(id)) {
      setLoaded(true);
    } else {
      dispatch(setSelectedProcessor(id, processorList))
        .then(() => setLoaded(true))
        .catch((error) => {
          console.log(error);
          setError(error);
        });
    }
  }, [dispatch, id, selectedProcessor, processorList, setError]);

  if (!loaded) {
    return <h1>Loading...</h1>;
  } else {
    return (
      <Component
        selectedProcessor={selectedProcessor}
        processorList={processorList}
        id={id}
        user={user}
      />
    );
  }
};
