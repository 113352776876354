import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";

import ErrorContext from "../../context/ErrorContext";
import { setSelectedTissue } from "../../actions/tissues";

const columns = [
  {
    field: "tissueName",
    headerName: "Tissue",
    width: 700,
  },
  {
    field: "ext_score",
    headerName: "Extended",
    width: 130,
  },
  {
    field: "reg_score",
    headerName: "Regional",
    width: 130,
  },
  {
    field: "flight_score",
    headerName: "Flight",
    width: 130,
  },
];

//list all tissues
export default function TissueList() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { setError } = useContext(ErrorContext);

  const tissueList = useSelector((state) => state.tissues.tissueList);

  const onTissueSelection = async (id) => {
    await dispatch(setSelectedTissue(id, tissueList))
      .then(() => history.push(`/tissues/${id}`))
      .catch((error) => {
        console.log(error);
        setError(error);
      });
  };
  return (
    <div className="container mt-3" style={{ height: 900, width: "100%" }}>
      <div className="header">
        <Link to="/tissues/new" className="btn header">
          Add Tissue
        </Link>
        <hr />
        <h1 style={{ textAlign: "center" }}>Current Tissues</h1>
        <hr />
      </div>
      <DataGrid
        rows={tissueList}
        columns={columns}
        onCellClick={async (params) => {
          await onTissueSelection(params.id);
        }}
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            fontSize: 15,
            color: "rgba(0, 0, 0, 0.5)",
            font: "bold",
          },
          "& .MuiDataGrid-row": {
            "&:nth-of-type(2n)": { backgroundColor: "#6e5b461a" },
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "#c9db2a38",
            cursor: "grab",
          },
        }}
      />
    </div>
  );
}
