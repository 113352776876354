import { useState, useContext } from 'react';
import { convertProcessorType } from '../../utils/processorUtils';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';

import { deleteProcessor } from '../../actions/processors';
import ErrorContext from '../../context/ErrorContext';
import LoadingContext from '../../context/LoadingContext';

function ProcessorDetail({ selectedProcessor, processorList, id, user }) {
  const dispatch = useDispatch();
  const history = useHistory();
  // error context & loading context
  const { setError } = useContext(ErrorContext);
  const { setLoading } = useContext(LoadingContext);
  // delete dialog
  const [isOpen, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (event) => {
    event.preventDefault();
    const data = selectedProcessor.id + `,${user.userId}`;
    setLoading(true);
    await dispatch(deleteProcessor(data))
      .then(() => {
        history.push('/processors');
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setError(error);
      });
  };

  return (
    <>
      <div className='container'>
        <Dialog
          fullWidth
          maxWidth='sm'
          open={isOpen}
          onClose={handleClose}>
          <DialogTitle>Delete Processor</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Confirm that you would like to delete this Processor
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button
              className='btn btn-danger'
              onClick={handleDelete}
              autoFocus>
              Confirm
            </button>
            <button
              className='btn btn-outline-dark'
              onClick={handleClose}>
              Close
            </button>
          </DialogActions>
        </Dialog>
        <div className='header'>
          <Link
            to='/processors'
            className='btn header'>
            Return to List
          </Link>
          <hr />
          <h1 style={{ textAlign: 'center' }}>Processor Information</h1>
          <hr />
        </div>
        <h5>
          <strong>Priority: </strong> {selectedProcessor.priority}
        </h5>
        <h5>
          <strong>Agency Name: </strong> {selectedProcessor.agencyName}
        </h5>
        <h5>
          <strong>Processor Type: </strong>
          {convertProcessorType(selectedProcessor.processorType.type)}
        </h5>
        <h5>
          <strong>Recovery Type/Tissue: </strong>{' '}
          {selectedProcessor.tissueType.tissueName}
        </h5>
        <hr />
        <h5>
          <strong>Age Min: </strong> {selectedProcessor.ageMin}{' '}
          <strong>Age Max: </strong> {selectedProcessor.ageMax}
        </h5>
        <h5>
          <strong>Gender: </strong> {selectedProcessor.gender}
        </h5>
        <h5>
          <strong>Extended: </strong> {selectedProcessor.extended ? '✔' : ''}{' '}
          <strong>Regional: </strong> {selectedProcessor.regional ? '✔' : ''}{' '}
          <strong>Flight: </strong> {selectedProcessor.flight ? '✔' : ''}{' '}
        </h5>
        <hr />
        <Link to={`/processors/${id}/edit`}>
          <button className='btn update'>Update Processor</button>
        </Link>
        <button
          className='btn btn-danger'
          onClick={handleOpen}>
          Delete Processor
        </button>
      </div>
    </>
  );
}

export default ProcessorDetail;
