import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunkMiddleWare from "redux-thunk";
import middlewareLogger from "./middleware/middleware-logger";
import rootReducer from "./reducers";
import { ErrorProvider } from "./context/ErrorContext";
import { LoadingProvider } from "./context/LoadingContext";
//enable redux browser extension
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunkMiddleWare, middlewareLogger))
);

ReactDOM.render(
  <LoadingProvider>
    <ErrorProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </ErrorProvider>
  </LoadingProvider>,
  document.getElementById("root")
);
