import { useEffect, useState } from 'react';
import './Version.css';

const Version = () => {
  const [commitHash, setCommitHash] = useState('');

  useEffect(() => {
    fetch('/version.json')
      .then((response) => response.json())
      .then((data) => setCommitHash(data.commitHash))
      .catch((error) =>
        console.error('An error occurred fetching the commit hash: ', error)
      );
  });

  return (
    <div className='hashDiv'>
      <p className='hash'>{commitHash}</p>
    </div>
  );
};

export default Version;
