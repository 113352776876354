import React, { useContext } from "react";
import ErrorContext from "../../context/ErrorContext";
import "./ErrorModal.css";
const ErrorModal = () => {
  const { error, setError } = useContext(ErrorContext);

  if (!error) {
    return null;
  }

  return (
    <div className="error-modal">
      <div className="error-modal-content">
        <h2>Something Went Wrong</h2>
        <hr />
        <p>{error.message}</p>
        <p>{error?.response?.data?.message?.toUpperCase()}</p>
        <hr />
        <p>
          Please email<a href="mailto:ithelpdesk@lcnw.org"> LCNW IT HelpDesk</a>{" "}
          for additional support
        </p>
        <button className="btn header" onClick={() => setError(null)}>
          Close
        </button>
      </div>
    </div>
  );
};

export default ErrorModal;
