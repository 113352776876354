import React, { useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";

import ErrorContext from "../../context/ErrorContext";
import NewHospitalForm from "./NewHospitalForm";
import HospitalList from "./HospitalList";
import HospitalDetail from "./HospitalDetail";
import EditHospitalForm from "./EditHospitalForm";
import { getHospitals } from "../../actions/hospitals";
import { HospitalLoader } from "./HospitalLoader";
import "./HospitalStyles.css";

const HospitalRouter = () => {
  const dispatch = useDispatch();

  const { setError } = useContext(ErrorContext);

  useEffect(() => {
    dispatch(getHospitals()).catch((error) => {
      console.log(error);
      setError(error);
    });
  }, [dispatch, setError]);

  return (
    <Switch>
      <Route path="/hospitals/new" component={NewHospitalForm} />
      <Route path="/hospitals/:id/edit">
        <HospitalLoader Component={EditHospitalForm} />
      </Route>
      <Route path="/hospitals/:id">
        <HospitalLoader Component={HospitalDetail} />
      </Route>
      <Route path="/hospitals" component={HospitalList} />
    </Switch>
  );
};

export default HospitalRouter;
