import { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';

import { deleteHospital } from '../../actions/hospitals';
import ErrorContext from '../../context/ErrorContext';

function HospitalDetail({ selectedHospital, user, id, hospitalList }) {
  const dispatch = useDispatch();
  const history = useHistory();
  // error context
  const { setError } = useContext(ErrorContext);
  // delete dialog
  const [isOpen, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (event) => {
    event.preventDefault();
    const data = selectedHospital.id + `,${user.userId}`;
    await dispatch(deleteHospital(data, hospitalList))
      .then(() => history.push('/hospitals'))
      .catch((error) => {
        console.log(error);
        setError(error);
      });
  };

  return (
    <div className='container mt-3'>
      <Dialog
        fullWidth
        maxWidth='sm'
        open={isOpen}
        onClose={handleClose}>
        <DialogTitle>Delete Hospital</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Confirm that you would like to delete this Hospital
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            className='btn btn-danger'
            onClick={handleDelete}
            autoFocus>
            Confirm
          </button>
          <button
            className='btn btn-outline-dark'
            onClick={handleClose}>
            Close
          </button>
        </DialogActions>
      </Dialog>
      <div className='header'>
        <Link
          to='/hospitals'
          className='btn header'>
          Return to List
        </Link>
        <hr />
        <h1>{selectedHospital.Hospital}</h1>
        <hr />
      </div>

      <div className='hospital_detail'>
        <div>
          <h5>
            <strong>MS_Skin_Ext: </strong>{' '}
            {selectedHospital.MS_Skin_Ext ? ' ✔' : ''}
          </h5>
          <h5>
            <strong>MS_Skin_Reg: </strong>{' '}
            {selectedHospital.MS_Skin_Reg ? '✔' : ''}
          </h5>
          <h5>
            <strong>MS_Skin_Flight: </strong>{' '}
            {selectedHospital.MS_Skin_Flight ? '✔' : ''}
          </h5>
        </div>
        <div>
          <h5>
            <strong>MS_Ext: </strong> {selectedHospital.MS_Ext ? '✔' : ''}
          </h5>
          <h5>
            <strong>MS_Reg: </strong> {selectedHospital.MS_Reg ? '✔' : ''}
          </h5>
          <h5>
            <strong>MS_Flight: </strong>{' '}
            {selectedHospital.MS_Flight ? '✔' : ''}
          </h5>
        </div>
        <div>
          <h5>
            <strong>Skin_Ext: </strong> {selectedHospital.Skin_Ext ? '✔' : ''}
          </h5>
          <h5>
            <strong>Skin_Reg: </strong> {selectedHospital.Skin_Reg ? '✔' : ''}
          </h5>
          <h5>
            <strong>Skin_Flight: </strong>{' '}
            {selectedHospital.Skin_Flight ? '✔' : ''}
          </h5>
        </div>
        <div>
          <h5>
            <strong>Hv_Ext: </strong> {selectedHospital.Hv_Ext ? '✔' : ''}
          </h5>
          <h5>
            <strong>Hv_Reg: </strong> {selectedHospital.Hv_Reg ? '✔' : ''}
          </h5>
          <h5>
            <strong>Hv_Flight: </strong>{' '}
            {selectedHospital.Hv_Flight ? '✔' : ''}
          </h5>
        </div>
        <div>
          <h5>
            <strong>HVN_Ext: </strong> {selectedHospital.HVN_Ext ? '✔' : ''}
          </h5>
          <h5>
            <strong>HVN_Reg: </strong> {selectedHospital.HVN_Reg ? '✔' : ''}
          </h5>
          <h5>
            <strong>HVN_Flight: </strong>{' '}
            {selectedHospital.HVN_Flight ? '✔' : ''}
          </h5>
        </div>
      </div>
      <hr />
      <Link to={`/hospitals/${id}/edit`}>
        <button className='btn update'>Update Hospital</button>
      </Link>
      <button
        className='btn btn-danger'
        onClick={handleOpen}>
        Delete Hospital
      </button>
    </div>
  );
}
export default HospitalDetail;
