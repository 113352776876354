import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Paper,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";

import AgencyList from "./AgencyList";
import {
  getProcessors,
  updateProcessorOrder,
  setSelectedProcessor,
} from "../../actions/processors";
import ErrorContext from "../../context/ErrorContext";
import LoadingContext from "../../context/LoadingContext";

//table of all processors indb, drag and drop to change priority & save btn to update db, view details of selected processor onClick
function ProcessorList() {
  const dispatch = useDispatch();
  const { setError } = useContext(ErrorContext);
  const { setLoading } = useContext(LoadingContext);

  const [orderedProcessors, setOrderedProcessors] = useState();
  const [loaded, setLoaded] = useState(false);
  const [saveEnable, setSaveEnabled] = useState(true);

  const processors = useSelector((state) => state.processors);
  const processorList = processors.processorList;

  // useEffect to handle url navigation instead of app click flow
  useEffect(() => {
    if (processorList && processorList.length) {
      setOrderedProcessors(processorList);
      setLoaded(true);
    } else {
      setLoading(true);
      dispatch(getProcessors())
        .then((res) => {
          setLoaded(true);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setError(error);
        });
    }
  }, [dispatch, processorList, setError, setLoading]);

  useEffect(() => {
    if (JSON.stringify(orderedProcessors) === JSON.stringify(processorList)) {
      setSaveEnabled(true);
    } else {
      setSaveEnabled(false);
    }
  }, [orderedProcessors, processorList]);

  //drag and drop processor table cells, to new array, update state
  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = [...orderedProcessors];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setOrderedProcessors(items);
  }
  //onClick save button, update db priority of processors based on where they are dragged to in the table
  async function handlePriority() {
    setLoading(true);
    await dispatch(updateProcessorOrder(orderedProcessors))
      .then((res) => {
        setOrderedProcessors(res);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setError(error);
      });
  }

  const handleSelectingProcessor = async (id) => {
    await dispatch(setSelectedProcessor(id, processorList)).catch((error) => {
      console.log(error);
      setError(error);
    });
  };

  return (
    loaded && (
      <div className="container mt-3">
        <div className="header">
          <Link to="/processors/new" className="btn header">
            Add Processor
          </Link>
          <hr />
          <h1>Current Processors</h1>
          <hr />
        </div>
        <h6 className="page_description">
          Click on the <span className="agency_outline">Agency Name</span>
          to view details, update information or delete.
        </h6>
        <hr />
        <h6 className="page_description">Save priority rankings</h6>
        <button
          onClick={handlePriority}
          className="btn update priority_order"
          disabled={saveEnable}
        >
          Save List
        </button>
        <AgencyList
          processorList={processorList}
          onProcessorSelection={handleSelectingProcessor}
        />
        <p>
          To change priority rankings, drag and drop the processors to order
        </p>
        <TableContainer component={Paper} align="center">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <strong>Priority</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Agency Name</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Processor Type</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Tissue Type</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Age Min</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Age Max</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Gender</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Extended</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Regional</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Flight</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="processors">
                {(provided) => (
                  <TableBody
                    className="processors"
                    aria-label="simple table"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {orderedProcessors.map((processor, index) => (
                      <Draggable
                        key={processor.id}
                        draggableId={"" + processor.id}
                        index={index}
                      >
                        {(provided) => (
                          <TableRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <TableCell align="center">
                              {processor.priority}
                            </TableCell>

                            <TableCell
                              scope="row"
                              id={processor.id}
                              align="center"
                            >
                              <Link
                                to={`/processors/${processor.id}`}
                                onClick={() =>
                                  handleSelectingProcessor(processor.id)
                                }
                              >
                                <div className="processor_link_div">
                                  <p>{processor.agencyName}</p>
                                </div>
                              </Link>
                            </TableCell>
                            <TableCell align="center">
                              {processor.processorType.type.toUpperCase()}
                            </TableCell>
                            <TableCell align="center">
                              {processor.tissueType.tissueName}
                            </TableCell>
                            <TableCell align="center">
                              {processor.ageMin}
                            </TableCell>
                            <TableCell align="center">
                              {processor.ageMax}
                            </TableCell>
                            <TableCell align="center">
                              {processor.gender}
                            </TableCell>
                            <TableCell align="center">
                              {processor.extended ? "✔" : ""}
                            </TableCell>
                            <TableCell align="center">
                              {processor.regional ? "✔" : ""}
                            </TableCell>
                            <TableCell align="center">
                              {processor.flight ? "✔" : ""}
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
        </TableContainer>
      </div>
    )
  );
}

export default ProcessorList;
