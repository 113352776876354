import { Menu, MenuItem } from "@mui/material";
import { useState } from "react";

export const ProcessorListForForm = ({ processors, selectedProcessor }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <button
        aria-label="More"
        id="processor_list_button"
        aria-controls="processor_list"
        aria-expanded={anchorEl ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        className="btn header"
      >
        Current Processor Priority
      </button>
      <Menu
        id="processor_list"
        open={anchorEl !== null}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handleClose}
        className="processor_drop_down"
      >
        {processors.map((processor) => (
          <MenuItem
            key={processor.id}
            disabled={
              selectedProcessor ? selectedProcessor.id !== processor.id : true
            }
          >
            Priority: <b>{processor.priority}</b>, Agency Name :{" "}
            <b>{processor.agencyName}</b>, Tissue Type:{" "}
            <b>{processor.tissueType.tissueName} </b>, Age Min:
            <b> {processor.ageMin}</b>, Age Max:
            <b> {processor.ageMax}</b>, Gender:
            <b>{processor.gender} </b>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
