// api calls for processors
import {
  CREATE_PROCESSOR,
  GET_PROCESSORS,
  SET_SELECTED_PROCESSOR,
  UPDATE_PROCESSOR,
  DELETE_PROCESSOR,
  DELETE_ALL_PROCESSORS,
  UPDATE_PROCESSOR_ORDER,
  GET_PROCESSOR_TYPES,
} from "./ActionTypes";
import ProcessorDataService from "../services/processor.service";

export const createProcessor = (data) => async (dispatch) => {
  try {
    const res = await ProcessorDataService.create(data);
    dispatch({
      type: CREATE_PROCESSOR,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getProcessors = () => async (dispatch) => {
  try {
    const res = await ProcessorDataService.getAll();
    dispatch({
      type: GET_PROCESSORS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateProcessor = (id, data) => async (dispatch) => {
  try {
    const res = await ProcessorDataService.update(id, data);
    dispatch({
      type: UPDATE_PROCESSOR,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteProcessor = (id) => async (dispatch) => {
  try {
    const res = await ProcessorDataService.delete(id);
    dispatch({
      type: DELETE_PROCESSOR,
      payload: { id },
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteAllProcessors = () => async (dispatch) => {
  try {
    const res = await ProcessorDataService.deleteAll();
    dispatch({
      type: DELETE_ALL_PROCESSORS,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findByProcessorName = (name) => async (dispatch) => {
  try {
    const res = await ProcessorDataService.findByName(name);
    dispatch({
      type: GET_PROCESSORS,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const setSelectedProcessor = (id, processors) => async (dispatch) => {
  //if processor is found in processors ignore the redundant api call
  let processor;
  if (processors.length)
    processor = processors.find(
      (processor) => Number(processor.id) === Number(id)
    );
  if (processor) {
    dispatch({ type: SET_SELECTED_PROCESSOR, payload: processor });
  } else {
    try {
      const res = await ProcessorDataService.get(id);
      dispatch({
        type: SET_SELECTED_PROCESSOR,
        payload: res.data,
      });
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
};

export const updateProcessorOrder = (processors) => async (dispatch) => {
  try {
    const res = await ProcessorDataService.updateOrder(processors);

    dispatch({
      type: UPDATE_PROCESSOR_ORDER,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getProcessorTypes = () => async (dispatch) => {
  try {
    const res = await ProcessorDataService.getAllProcessorTypes();
    dispatch({
      type: GET_PROCESSOR_TYPES,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    console.log("from thunk", err);
    return Promise.reject(err);
  }
};
