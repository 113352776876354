import { useEffect, useContext } from "react";
import { Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import LoadingContext from "../../context/LoadingContext";
import ErrorContext from "../../context/ErrorContext";
import NewProcessorForm from "./NewProcessorForm";
import EditProcessorForm from "./EditProcessorForm";
import ProcessorDetail from "./ProcessorDetail";
import ProcessorList from "./ProcessorList";
import { getProcessors, getProcessorTypes } from "../../actions/processors";
import { getTissues } from "../../actions/tissues";
import { ProcessorLoader } from "./ProcessorLoader";
import "./ProcessorStyles.css";

const ProcessorRouter = () => {
  const dispatch = useDispatch();

  const { setError } = useContext(ErrorContext);
  const { setLoading } = useContext(LoadingContext);

  const processorList = useSelector((state) => state.processors.processorList);

  useEffect(() => {
    if (processorList === undefined || !processorList.length) {
      setLoading(true);
      dispatch(getProcessors())
        .then(() => dispatch(getProcessorTypes()))
        .then(() => dispatch(getTissues()))
        .then(() => setLoading(false))
        .catch((error) => {
          console.log(error);
          setError(error);
        });
    }
  }, [dispatch, setError, processorList, setLoading]);
  return (
    <Switch>
      <Route path="/processors/new" component={NewProcessorForm} />
      <Route path="/processors/:id/edit">
        <ProcessorLoader Component={EditProcessorForm} />
      </Route>
      <Route path="/processors/:id">
        <ProcessorLoader Component={ProcessorDetail} />
      </Route>
      <Route path="/processors" component={ProcessorList} />
    </Switch>
  );
};

export default ProcessorRouter;
