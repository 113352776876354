import {
  CREATE_PROCESSOR,
  GET_PROCESSORS,
  UPDATE_PROCESSOR,
  DELETE_PROCESSOR,
  DELETE_ALL_PROCESSORS,
  GET_PROCESSOR_TYPES,
  SET_SELECTED_PROCESSOR,
  UPDATE_PROCESSOR_ORDER,
} from "../actions/ActionTypes";

const initalState = {
  processorList: [],
  processorTypes: [],
  selectedProcessor: null,
};

function processorReducer(state = { ...initalState }, action) {
  const { type, payload } = action;
  const newState = { ...state };

  switch (type) {
    case CREATE_PROCESSOR:
      newState.processorList = payload.updatedProcessors;
      newState.selectedProcessor = payload.newProcessor;
      return newState;
    case GET_PROCESSORS:
      newState.processorList = payload;
      return newState;
    case UPDATE_PROCESSOR:
      newState.processorList = payload.updatedProcessors;
      newState.selectedProcessor = payload.updatedProcessor;
      return newState;
    case DELETE_PROCESSOR:
      newState.processorList = payload.updatedProcessors;
      return newState;
    case DELETE_ALL_PROCESSORS:
      newState.processorList = [];
      return newState;
    case SET_SELECTED_PROCESSOR:
      newState.selectedProcessor = payload;
      return newState;
    case GET_PROCESSOR_TYPES:
      newState.processorTypes = payload;
      return newState;
    case UPDATE_PROCESSOR_ORDER:
      newState.processorList = payload;
      return newState;
    default:
      return newState;
  }
}

export default processorReducer;
