import React, { Component } from "react";
import {
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Autocomplete,
} from "@mui/material";

import HospitalDataService from "../../services/hospital.service";
import ScreenerResult from "./ScreenerResult.js";
import logoGreen from "../../img/logoGreenNoBgTxt.png";
import "./ScreenerForm.css";

class ScreenerForm extends Component {
  constructor(props) {
    super(props);
    this.retrieveHospitals = this.retrieveHospitals.bind(this);
    this.state = {
      hospitals: [],
      resultVisibleOnPage: false,
      chosenHospital: "",
      chosenAge: "years",
      ageNumber: "",
      gender: "",
      donorData: {
        chosenHospital: "",
        chosenAge: "",
        ageNumber: "",
        gender: "",
      },
    };
  }
  //get all hospitals from db
  componentDidMount() {
    this.retrieveHospitals();
  }
  //get all hospitals from db and set to state
  retrieveHospitals() {
    HospitalDataService.getAll()
      .then((response) => {
        this.setState({
          hospitals: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  //get values of inputs
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };
  //get hospital input
  handleHospitalChange(event, value, name) {
    this.setState({ [name]: value });
  }
  //handle form submission
  handleScreenerFormSubmission = (event) => {
    event.preventDefault();
    this.setState({
      resultVisibleOnPage: true,
      donorData: {
        chosenHospital: this.state.chosenHospital,
        chosenAge: this.state.chosenAge,
        ageNumber: this.state.ageNumber,
        gender: this.state.gender,
      },
    });
  };
  //back to form function
  handleBackButton = () => {
    if (this.state.resultVisibleOnPage === true) {
      this.setState({
        resultVisibleOnPage: false,
      });
    }
  };

  render() {
    const { hospitals } = this.state;
    const showResults = this.state.resultVisibleOnPage;
    let content;
    if (showResults) {
      content = (
        <ScreenerResult
          onNewDataResults={this.state.donorData}
          onBackButton={this.handleBackButton}
        />
      );
    } else {
      content = (
        <div
          className="container mt-3"
          style={{
            alignItems: "center",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <img src={logoGreen} alt="Tissue Screener Logo" className="imgSize" />
          <form
            onSubmit={this.handleScreenerFormSubmission}
            style={{ maxWidth: "417px", justifySelf: "center" }}
          >
            <hr />
            <TextField
              required
              name="ageNumber"
              onChange={this.handleChange}
              defaultValue={this.state.ageNumber ? this.state.ageNumber : ""}
              id="outlined-number"
              label="Donor Age"
              type="number"
              InputProps={{ inputProps: { min: 0, max: 130 } }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              helperText="Please choose age in months if less than one year"
            />
            <FormControl component="fieldset">
              <FormLabel component="legend"></FormLabel>
              <RadioGroup
                name="chosenAge"
                value={this.state.chosenAge}
                onChange={this.handleChange}
                style={{ marginLeft: "10%" }}
              >
                <FormControlLabel
                  value="years"
                  control={<Radio color="primary" required={true} />}
                  label="Years"
                />
                <FormControlLabel
                  value="months"
                  control={<Radio color="primary" required={true} />}
                  label="Months"
                />
              </RadioGroup>
            </FormControl>
            <hr />
            <FormControl component="fieldset">
              <FormLabel component="legend">Gender</FormLabel>
              <RadioGroup
                name="gender"
                onChange={this.handleChange}
                value={this.state.gender}
                row
              >
                <FormControlLabel
                  value="F"
                  control={<Radio color="primary" required={true} />}
                  label="Female"
                />
                <FormControlLabel
                  value="M"
                  control={<Radio color="primary" required={true} />}
                  label="Male"
                />
              </RadioGroup>
            </FormControl>
            <hr />
            <Autocomplete
              id="combo-box"
              options={hospitals.sort((a, b) =>
                a.Hospital.localeCompare(b.Hospital)
              )}
              getOptionLabel={(hospital) => hospital.Hospital}
              style={{ width: 300 }}
              onChange={(event, value) =>
                this.handleHospitalChange(event, value, "chosenHospital")
              }
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              name="chosenHospital"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    this.state.chosenHospital
                      ? this.state.chosenHospital.Hospital
                      : "Hospitals"
                  }
                  variant="outlined"
                  required
                />
              )}
            />
            <hr />
            <button
              className="btn"
              style={{ backgroundColor: "#c8db2a" }}
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>
      );
    }
    return <>{content}</>;
  }
}

export default ScreenerForm;
