//api calls for hospitals
import {
  CREATE_HOSPITAL,
  GET_HOSPITALS,
  UPDATE_HOSPITAL,
  DELETE_HOSPITAL,
  DELETE_ALL_HOSPITALS,
  SET_SELECTED_HOSPITAL,
} from "./ActionTypes";
import HospitalDataService from "../services/hospital.service";
export const createHospital = (data) => async (dispatch) => {
  try {
    const res = await HospitalDataService.create(data);
    dispatch({
      type: CREATE_HOSPITAL,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getHospitals = () => async (dispatch) => {
  try {
    const res = await HospitalDataService.getAll();
    dispatch({
      type: GET_HOSPITALS,
      payload: res.data,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateHospital = (id, data, hospitalList) => async (dispatch) => {
  try {
    const res = await HospitalDataService.update(id, data);
    const updatedHospitalList = hospitalList.map((hospital) => {
      if (hospital.id === Number(id)) {
        return res.data.hospital;
      } else {
        return hospital;
      }
    });
    dispatch({
      type: UPDATE_HOSPITAL,
      payload: { hospital: res.data, hospitalList: updatedHospitalList },
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteHospital = (id, hospitalList) => async (dispatch) => {
  try {
    const res = await HospitalDataService.delete(id);
    const updatedHospitalList = hospitalList.filter(
      (hospital) => hospital.id !== Number(id.split(",")[0])
    );
    dispatch({
      type: DELETE_HOSPITAL,
      payload: { hospitalList: updatedHospitalList },
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteAllHospitals = () => async (dispatch) => {
  try {
    const res = await HospitalDataService.deleteAll();
    dispatch({
      type: DELETE_ALL_HOSPITALS,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findByHospitalName = (hospital) => async (dispatch) => {
  try {
    const res = await HospitalDataService.findByHospitalName(hospital);
    dispatch({
      type: GET_HOSPITALS,
      payload: res.data,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const setSelectedHospital = (id, hospitals) => async (dispatch) => {
  //if tissue is found in tissues ignore the redundant api call
  let hospital;
  if (hospitals.length)
    hospital = hospitals.find((hospital) => Number(hospital.id) === Number(id));
  if (hospital) {
    dispatch({ type: SET_SELECTED_HOSPITAL, payload: hospital });
  } else {
    try {
      const res = await HospitalDataService.get(id);
      dispatch({
        type: SET_SELECTED_HOSPITAL,
        payload: res.data,
      });
    } catch (err) {
      return Promise.reject(err);
    }
  }
};
