import React, { useState, useRef, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import {
  FormControl,
  TextField,
  MenuItem,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Container,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";

import { convertProcessorType } from "../../utils/processorUtils";
import { updateProcessor } from "../../actions/processors";
import ErrorContext from "../../context/ErrorContext";
import LoadingContext from "../../context/LoadingContext";
import { ProcessorListForForm } from "./ProcessorListForForm";

export default function EditProcessorForm({
  selectedProcessor,
  processorList,
  user,
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { setError } = useContext(ErrorContext);
  const { setLoading } = useContext(LoadingContext);

  const [priority, setPriority] = useState(1);
  const [chosenProcessorType, setChosenProcessorType] = useState(
    selectedProcessor.processorTypeId
  );
  const [chosenTissue, setChosenTissueType] = useState(
    selectedProcessor.tissueTypeId
  );

  const tissues = useSelector((state) => state.tissues.tissueList);
  const processorTypes = useSelector(
    (state) => state.processors.processorTypes
  );

  // check if age min is decimal if input is > 1 and truncs || strips the decimal
  let ageMinRef = useRef();
  function checkDecimal() {
    if (ageMinRef.current.value > 1) {
      ageMinRef.current.value = Math.trunc(ageMinRef.current.value);
    }
  }
  // get chosen tissue object
  function handleTissueChange(event) {
    setChosenTissueType(event.target.value);
  }
  function handleProcessorTypeChange(event) {
    setChosenProcessorType(event.target.value);
  }
  // edit processor form submission
  async function handleEditProcessorFormSubmission(event) {
    event.preventDefault();
    const chosenTissue = JSON.parse(event.target.tissueType.value);
    const extended = event.target.extended.checked;
    const regional = event.target.regional.checked;
    const flight = event.target.flight.checked;
    let ext_score = extended ? chosenTissue.ext_score : "";
    let reg_score = regional ? chosenTissue.reg_score : "";
    let flight_score = flight ? chosenTissue.flight_score : "";
    const updatedProcessor = {
      agencyName: event.target.agencyName.value,
      gender: event.target.gender.value,
      ageMin: parseInt(event.target.ageMin.value),
      ageMax: parseInt(event.target.ageMax.value),
      extended: extended,
      regional: regional,
      flight: flight,
      ext_score: ext_score,
      reg_score: reg_score,
      flight_score: flight_score,
      id: selectedProcessor.id,
      priority,
      userId: user.userId,
      tissueTypeId: chosenTissue,
      processorTypeId: chosenProcessorType,
    };
    setLoading(true);
    await dispatch(updateProcessor(selectedProcessor.id, updatedProcessor))
      .then(() => {
        history.goBack();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  }

  return (
    <div className="container mt-3">
      <div className="header">
        <Link to="/processors" className="btn header">
          Return to List
        </Link>
        <hr />
        <h1 className="header_text">Edit Processor</h1>
        <hr />
        <ProcessorListForForm
          processors={processorList}
          selectedProcessor={selectedProcessor}
        />
      </div>
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
        }}
      >
        <form onSubmit={handleEditProcessorFormSubmission}>
          <TextField
            required
            name="agencyName"
            defaultValue={selectedProcessor.agencyName}
            label="Agency Name"
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControl required style={{ minWidth: 450, margin: "3% 0 3%" }}>
            <FormGroup>
              <TextField
                id="processorType"
                select
                required
                label="Processor Type"
                name="processorType"
                variant="outlined"
                onChange={handleProcessorTypeChange}
                InputLabelProps={{
                  shrink: true,
                }}
                value={chosenProcessorType}
                helperText={`${convertProcessorType(
                  selectedProcessor.processorType.type
                )} (Original Value)`}
              >
                {processorTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {`${convertProcessorType(type.type)} Processor`}
                  </MenuItem>
                ))}
              </TextField>
            </FormGroup>
          </FormControl>
          <br />
          <FormControl required style={{ minWidth: 450, margin: "3% 0 3%" }}>
            <FormGroup>
              <TextField
                id="tissueType"
                select
                required
                label="Tissue"
                name="tissueType"
                variant="outlined"
                onChange={handleTissueChange}
                InputLabelProps={{
                  shrink: true,
                }}
                value={chosenTissue}
                helperText={`${selectedProcessor.tissueType.tissueName} (Original Value)`}
              >
                {[...tissues]
                  .sort((a, b) => a.tissueName.localeCompare(b.tissueName))
                  .map((tissue) => (
                    <MenuItem key={tissue.id} value={tissue.id}>
                      {tissue.tissueName}
                    </MenuItem>
                  ))}
              </TextField>
            </FormGroup>
          </FormControl>
          <br />
          <Grid container direction="row">
            <Grid item>
              <TextField
                required
                onChange={checkDecimal}
                InputProps={{ inputProps: { min: 0, max: 130, step: 0.01 } }}
                name="ageMin"
                type="number"
                inputRef={ageMinRef}
                defaultValue={selectedProcessor.ageMin}
                label="Age Min"
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                required
                style={{ marginLeft: "20px" }}
                name="ageMax"
                type="number"
                InputProps={{ inputProps: { min: 0, max: 130, step: 0.01 } }}
                defaultValue={selectedProcessor.ageMax}
                label="Age Max"
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={2} alignSelf="center">
              <TextField
                required
                InputProps={{
                  inputProps: { min: 1, max: processorList.length, step: 1 },
                }}
                defaultValue={selectedProcessor.priority}
                name="priority"
                type="number"
                label="Priority"
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setPriority(Number(e.target.value))}
              />
            </Grid>
            <Grid
              item
              xs={10}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#212529",
              }}
            >
              <p>
                *** Reference The Current Processor Priority Above If Needed ***
              </p>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "3%" }}>
            <Grid item>
              <FormControl component="fieldset">
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup
                  name="gender"
                  defaultValue={selectedProcessor.gender}
                  row
                >
                  <FormControlLabel
                    value="F"
                    control={<Radio required={true} />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="M"
                    control={<Radio required={true} />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="MF"
                    control={<Radio required={true} />}
                    label="Both"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "3%" }}>
            <Grid item>
              <FormControl component="fieldset">
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="1"
                        name="extended"
                        defaultChecked={
                          selectedProcessor.extended ? true : false
                        }
                      />
                    }
                    label="Extended"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="1"
                        name="regional"
                        defaultChecked={
                          selectedProcessor.regional ? true : false
                        }
                      />
                    }
                    label="Regional"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="1"
                        name="flight"
                        defaultChecked={selectedProcessor.flight ? true : false}
                      />
                    }
                    label="Flight"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
          <button className="btn update edit_processor" type="submit">
            Update
          </button>
          <button
            className="btn btn-danger edit_processor"
            type="button"
            onClick={() => history.goBack()}
          >
            Cancel Update
          </button>
        </form>
      </Container>
    </div>
  );
}
