import { combineReducers } from "redux";
import hospitalReducer from "./hospitals";
import processorReducer from "./processors";
import tissueReducer from "./tissues";
import userReducer from "../actions/user";

const rootReducer = combineReducers({
  hospitals: hospitalReducer,
  processors: processorReducer,
  tissues: tissueReducer,
  users: userReducer,
});

export default rootReducer;
