import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import React, { useState, useRef, useContext } from "react";
import {
  FormControl,
  TextField,
  MenuItem,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Container,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";

import { convertProcessorType } from "../../utils/processorUtils";
import { createProcessor } from "../../actions/processors";
import { ProcessorListForForm } from "./ProcessorListForForm";
import ErrorContext from "../../context/ErrorContext";
import LoadingContext from "../../context/LoadingContext";

export default function NewProcessorForm() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { setError } = useContext(ErrorContext);
  const { setLoading } = useContext(LoadingContext);

  const [chosenTissue, setChosenTissueType] = useState();
  const [chosenProcessorType, setChosenProcessorType] = useState();
  const [priority, setPriority] = useState(1);

  const userId = useSelector((state) => state.users.newUser.userId);
  const tissues = useSelector((state) => state.tissues.tissueList);
  const processorTypes = useSelector(
    (state) => state.processors.processorTypes
  );
  const processors = useSelector((state) => state.processors.processorList);

  // check if age min is decimal if input is > 1 and truncs || strips the decimal
  let ageMinRef = useRef();
  function checkDecimal() {
    if (ageMinRef.current.value > 1) {
      ageMinRef.current.value = Math.trunc(ageMinRef.current.value);
    }
  }

  async function handleNewProcessorFormSubmission(event) {
    event.preventDefault();
    const chosenTissue = JSON.parse(event.target.tissueType.value);
    const extended = event.target.extended.checked;
    const regional = event.target.regional.checked;
    const flight = event.target.flight.checked;
    let ext_score = extended ? chosenTissue.ext_score : "";
    let reg_score = regional ? chosenTissue.reg_score : "";
    let flight_score = flight ? chosenTissue.flight_score : "";
    setLoading(true);
    await dispatch(
      createProcessor({
        agencyName: event.target.agencyName.value,
        gender: event.target.gender.value,
        ageMin: parseInt(event.target.ageMin.value),
        ageMax: parseInt(event.target.ageMax.value),
        extended: extended,
        regional: regional,
        flight: flight,
        ext_score: ext_score,
        reg_score: reg_score,
        flight_score: flight_score,
        userId: userId,
        priority,
        tissueTypeId: chosenTissue.id,
        processorTypeId: chosenProcessorType.id,
      })
    )
      .then(() => {
        history.push("/processors");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setError(error);
      });
  }
  function handleTissueChange(event) {
    setChosenTissueType(event.target.value);
  }
  function handleProcessorTypeChange(event) {
    setChosenProcessorType(event.target.value);
  }

  return (
    <div className="container mt-3">
      <div className="header">
        <Link to="/processors" className="btn header">
          Return to List
        </Link>
        <hr />
        <h1 className="header_text">New Processor</h1>
        <hr />
        <ProcessorListForForm processors={processors} />
      </div>
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
        }}
      >
        <form onSubmit={handleNewProcessorFormSubmission}>
          <TextField
            required
            name="agencyName"
            label="Agency Name"
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControl required style={{ minWidth: 450, margin: "3% 0 3%" }}>
            <FormGroup>
              <TextField
                id="processorType"
                select
                required
                label="Processor Type"
                name="processorType"
                variant="outlined"
                onChange={handleProcessorTypeChange}
                InputLabelProps={{
                  shrink: true,
                }}
                value={chosenProcessorType || ""}
              >
                <MenuItem value="" disabled>
                  Processor Types
                </MenuItem>
                {processorTypes.map((type) => (
                  <MenuItem key={type.id} value={type}>
                    {`${convertProcessorType(type.type)} Processor`}
                  </MenuItem>
                ))}
              </TextField>
            </FormGroup>
          </FormControl>
          <br />
          <FormControl required style={{ minWidth: 450, margin: "3% 0 3%" }}>
            <FormGroup>
              <TextField
                id="tissueType"
                select
                required
                label="Tissue"
                name="tissueType"
                variant="outlined"
                onChange={handleTissueChange}
                InputLabelProps={{
                  shrink: true,
                }}
                value={chosenTissue || ""}
              >
                <MenuItem value="" disabled>
                  Tissue Types
                </MenuItem>
                {[...tissues]
                  .sort((a, b) => a.tissueName.localeCompare(b.tissueName))
                  .map((tissue) => (
                    <MenuItem
                      key={tissue.id}
                      value={JSON.stringify(tissue) || ""}
                    >
                      {tissue.tissueName}
                    </MenuItem>
                  ))}
              </TextField>
            </FormGroup>
          </FormControl>
          <br />
          <Grid container direction="row">
            <Grid item>
              <TextField
                required
                onChange={checkDecimal}
                InputProps={{
                  inputProps: { min: 0, max: 130, step: 0.01 },
                }}
                name="ageMin"
                type="number"
                inputRef={ageMinRef}
                label="Age Min"
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                required
                style={{ marginLeft: "20px" }}
                name="ageMax"
                type="number"
                onChange={checkDecimal}
                InputProps={{ inputProps: { min: 0, max: 130, step: 0.01 } }}
                label="Age Max"
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={2} alignSelf="center">
              <TextField
                required
                InputProps={{
                  inputProps: { min: 1, max: processors.length + 1, step: 1 },
                }}
                name="priority"
                type="number"
                label="Priority"
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setPriority(Number(e.target.value))}
              />
            </Grid>
            <Grid
              item
              xs={10}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#212529",
              }}
            >
              <p>
                *** Reference The Current Processor Priority Above If Needed ***
              </p>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "3%" }}>
            <Grid item>
              <FormControl component="fieldset">
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup name="gender" row>
                  <FormControlLabel
                    value="F"
                    control={<Radio required={true} />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="M"
                    control={<Radio required={true} />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="MF"
                    control={<Radio required={true} />}
                    label="Both"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "3%" }}>
            <Grid item>
              <FormControl component="fieldset">
                <FormGroup row>
                  <FormControlLabel
                    control={<Checkbox value="1" name="extended" />}
                    label="Extended"
                  />
                  <FormControlLabel
                    control={<Checkbox value="1" name="regional" />}
                    label="Regional"
                  />
                  <FormControlLabel
                    control={<Checkbox value="1" name="flight" />}
                    label="Flight"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
          <button
            className="btn"
            type="submit"
            style={{ backgroundColor: "#ffd006", marginTop: "3%" }}
          >
            Submit
          </button>
        </form>
      </Container>
    </div>
  );
}
